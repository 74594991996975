import React from 'react';
// import { Link as GatsbyLink } from 'gatsby';
import {
  Flex,
  Box,
  Text,
  Button,
  Container,
} from 'components';
import { useIntl } from 'context/IntlContext';
import { useLinks } from 'context/LinksContext';
import Link from 'components/Link';
import TableSection from '../../../../../components/TableSection';

const SmartInvestments = () => {
  const { t } = useIntl();
  const { login, signup } = useLinks();
  
  // Change Dynamic product's minInvestment to 30,000 between 01/04/24 and 30/06/24
  const today = new Date();
  const startDate = new Date('2024-04-01');
  const endDate = new Date('2024-06-30');
  const isBetween = startDate <= today && today <= endDate;
  const minInvestmentDynamic = isBetween ? "30,000" : "50,000";

  return (
    <Box sx={{ bg: 'primaryBackground', pb: [11, 11, 11, 0] }}>
      <Container>
        <Flex sx={{ justifyContent: 'center' }}>
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <Text as="p" variant="sectionTitle">
              {t('homepage.marketplace.investment_section.title')}
            </Text>
            <Box
              sx={{
                pb: 13,
              }}
            >
              <Text
                variant="underHero"
                sx={{
                  color: 'quaternary',
                  opacity: '0.8',
                  pt: 5,
                }}
              >
                {t('homepage.marketplace.investment_section.subtitle') ||
                  'Marketplace investments provide projected interest which is expected to be paid on a daily basis. By opening an account you can invest with a projected interest rate starting at 2.5% AER.'}
              </Text>
            </Box>
            <Flex
              sx={{
                backgroundColor: 'quinaryBackground',
                borderRadius: '8px',
                paddingTop: '32px',
                paddingRight: '32px',
                paddingBottom: '32px',
                paddingLeft: '64px',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                display: 'flex',
                '@media (max-width: 768px)': {
                  flexDirection: 'column',
                  p: '24px',
                },
              }}
            >
              <Flex
                sx={{
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  display: 'flex',
                  '@media (max-width: 768px)': {
                    marginBottom: '24px',
                  },
                }}
              >
                <Text
                  sx={{
                    lineHeight: '32px',
                    fontWeight: 600,
                    fontSize: '24px',
                    textAlign: 'start',
                  }}
                >
                  {t('homepage.marketplace.investment_section.cta.title')}
                </Text>
                <Text sx={{ lineHeight: '24px', textAlign: 'start' }}>
                  {t('homepage.marketplace.investment_section.cta.subtitle')}
                </Text>
              </Flex>
              <Flex
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  display: 'flex',
                  '@media (max-width: 768px)': {
                    width: '100%',
                  },
                }}
              >
                <Button
                  as={Link}
                  to={login}
                  variant="secondary"
                  type="submit"
                  sx={{
                    borderColor: 'primary',
                    flexShrink: 0,
                    m: '0 auto',
                    mb: [2, 2, 0],
                    width: ['100%', '100%', 'auto'],
                    '@media (max-width: 768px)': {
                      mb: 0,
                      mr: 1,
                      flex: 1,
                    },
                  }}
                >
                  {t('global.buttons.login')}
                </Button>
                <Button
                  as={Link}
                  to={signup.link}
                  variant="primary"
                  type="submit"
                  sx={{
                    flexShrink: 0,
                    m: '0 auto',
                    ml: [0, 0, 2],
                    width: ['100%', '100%', 'auto'],
                    '@media (max-width: 768px)': {
                      ml: 1,
                      flex: 1,
                    },
                  }}
                >
                  {t('global.buttons.signup')}
                </Button>
              </Flex>
            </Flex>
              <Text
                sx={{
                  color: '#233143',
                  fontSize: '12px',
                  lineHeight: '24px',
                  marginTop: '16px',
                  marginBottom: '44px',
                  display: 'block'
                }}
              >
                {t('marketplace.provider.cta.warning')}
              </Text>
            <Flex
              sx={{
                justifyContent: 'space-around',
                flexDirection: ['column', 'row'],
                flexWrap: 'wrap',
              }}
            >
              <TableSection
                accountType={t(
                  'homepage.marketplace.investment_section.account_type.account.term',
                )}
                bgAccountType="primary"
                colorAccountType="tertiary"
                firstTab={t('global.product_name.account')}
                secondTab="2.5"
                minInvestment="1"
              />
              <TableSection
                accountType={t(
                  'homepage.marketplace.investment_section.account_type.one.term',
                )}
                bgAccountType=""
                colorAccountType="primary"
                firstTab={t('global.product_name.one')}
                secondTab="3"
                minInvestment="1,000"
              />
              <TableSection
                accountType={t(
                  'homepage.marketplace.investment_section.account_type.market.term',
                )}
                bgAccountType=""
                colorAccountType="primary"
                firstTab={t('global.product_name.market')}
                secondTab="4"
                minInvestment="1,000"
              />
              <TableSection
                accountType={t('global.term.five_years')}
                bgAccountType=""
                colorAccountType="primary"
                firstTab={t('global.product_name.dynamic')}
                secondTab="6"
                minInvestment={minInvestmentDynamic}
              />
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default SmartInvestments;
